<template>
  <div
    class="d-flex flex-column"
    v-if="item.data.length"
  >
    <v-sparkline
      :value="item.data"
      :gradient="['#5DBCD2', '#4CAF50']"
      smooth="6"
      line-width="6"
      auto-draw
    />
    <div class="infos">
      <div class="title">
        <trend
          :reverse="reverse"
          :value="item.trend"
        />{{last}}
      </div>
    </div>
    <div class="overline title">{{title}}</div>
  </div>
</template>

<script>
export default {
  components: {
    Trend: () => import('./trend')
  },
  computed: {
    last () {
      const last = this.item.data.length - 1
      return this.item.data[last]
    }
  },
  props: {
    item: Object,
    reverse: Boolean,
    title: String
  }
}
</script>

<style lang="stylus" scoped>
.infos
  align-self flex-end
  text-align right

.title
  display flex
  justify-content flex-end
  text-align right
  margin-right 0.5rem
</style>
